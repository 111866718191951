// extracted by mini-css-extract-plugin
export var addButton = "HeroBottom-module--addButton--7b1ea";
export var addButtonSimple = "HeroBottom-module--addButtonSimple--1f7a2";
export var body = "HeroBottom-module--body--d075e";
export var buttonText = "HeroBottom-module--buttonText--c8b33";
export var buttonTextLight = "HeroBottom-module--buttonTextLight--3ff48";
export var buttonTextLightSameLine = "HeroBottom-module--buttonTextLightSameLine--3718c";
export var buttonTextSameLine = "HeroBottom-module--buttonTextSameLine--8302e";
export var buyButtonCart = "HeroBottom-module--buyButtonCart--88556";
export var cardImage = "HeroBottom-module--cardImage--bfc23";
export var heroBottomContainer = "HeroBottom-module--heroBottomContainer--63707";
export var heroButton = "HeroBottom-module--heroButton--03fef";
export var pad = "HeroBottom-module--pad--66c4a";
export var subtractButton = "HeroBottom-module--subtractButton--89b23";
export var title = "HeroBottom-module--title--1bb6d";