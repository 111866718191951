import React from 'react';
import { Container, Col, Row, Image, Button } from 'react-bootstrap';
import { Link } from 'gatsby';
import {
  pad,
  cardImage,
  buttonText,
  buttonTextLight,
  buttonTextSameLine,
  buttonTextLightSameLine,
  body,
  title,
  heroButton,
  heroBottomContainer,
} from './HeroBottom.module.scss';
import useLanguageStore from '../LanguageStore/LanguageStore';
import cardsImage from '../../../static/images/terminal-contactless.jpg';

<style>
  @import
  url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@1,100&display=swap');
</style>;

type LanguageContent = {
  title: string;
  bodyText: string;
  buttonText: string;
};

const content: { [name: string]: LanguageContent } = {};
content.English = {
  title: 'Find out how much you can save!',
  bodyText:
    'We calculate and find the best price for your company. ' +
    'Find out how much you can save, \nit only takes a minute.',
  buttonText: 'Compare in just a minute',
};
content.Español = {
  title: '¡Comprueba cuanto puedes ahorrar!',
  bodyText:
    'Este servicio no cuesta nada, ' +
    'nosotros encontramos los mejores contratos ' +
    'para tu negocio. ',
  // '¡Es fácil y toma solo un minuto!',
  buttonText: 'Compara en solo un minuto',
};

const HeroBottom = () => {
  const language = useLanguageStore((state) => state.language);
  let choosenLanguage: LanguageContent;

  language === 'Español'
    ? (choosenLanguage = content.Español)
    : (choosenLanguage = content.English);

  return (
    <div className={heroBottomContainer}>
      <div style="height: 150px; overflow: hidden;">
        <svg
          viewBox="0 0 500 150"
          preserveAspectRatio="none"
          style="height: 100%; width: 100%;"
        >
          <path
            d="M0.00,49.98 C150.00,150.00 271.49,-49.98 500.00,49.98 L500.00,0.00 L0.00,0.00 Z"
            style="stroke: none; fill: #ffffff;"
          />
        </svg>
      </div>
      <Container>
        <Row>
          <Col lg="6" md="12">
            <Image
              className={cardImage}
              src={cardsImage}
              alt="Comprando con tarjeta credito en terminal bancaria"
              title="Comprando con tarjeta credito en terminal bancaria"
              fluid
            />
          </Col>
          <Col lg="6" md="12">
            <div>
              <div className={pad} />
              <h2 className={title}>{choosenLanguage.title}</h2>
              <div className={pad} />
              <h3 className={body}>{choosenLanguage.bodyText}</h3>
              <div className={pad} />
              <Link to="preferencias">
                <Button className={heroButton} variant="success" size="lg">
                  <div className={buttonTextLightSameLine}>
                    {choosenLanguage.buttonText}
                  </div>
                </Button>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
      <div className={pad} />
      <div style="height: 150px; overflow: hidden;">
        <svg
          viewBox="0 0 500 150"
          preserveAspectRatio="none"
          style="height: 100%; width: 100%;"
        >
          <path
            d="M0.00,49.98 C150.00,150.00 349.20,-49.98 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
            style="stroke: none; fill: #d7e5f0;"
          />
        </svg>
      </div>
    </div>
  );
};

export default HeroBottom;
