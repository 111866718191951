/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useEffect, useState } from 'react';
import { Container, Col, Row, Image, Button } from 'react-bootstrap';
import { Link } from 'gatsby';
import useLanguageStore from '../LanguageStore/LanguageStore';
import {
  miniPad,
  pad,
  padBig,
  cardImage,
  imageColumn,
  buttonText,
  buttonTextLight,
  buttonTextSameLine,
  buttonTextLightSameLine,
  body,
  title,
  heroButton,
  heroContainer,
} from './Hero.module.scss';
import comprarConTarjeta from '../../../static/images/compra-con-tarjeta.jpg';
import PartnerCarousel from '../PartnerCarousel/PartnerCarousel';

type LanguageContent = {
  title: string;
  bodyText: string;
  buttonText: string;
};

const content: { [name: string]: LanguageContent } = {};
content.English = {
  title: 'Compare payment solutions \nand start saving today!',
  bodyText:
    'PuntoTerminal is the only independent and free service for comparing payment services.', // +
  // '\n\nFind out how much you can save', // \nit only takes a minute!',
  // '\nWe calculate and find the cheapest offer for your company!' +

  buttonText: 'Compare in just a minute',
};
content.Español = {
  title: '¡Encuentra la mejor solución de pago!', // '¡Compara soluciones de pago, \nEmpieza a ahorrar hoy!',
  bodyText:
    'PuntoTerminal es el único independiente y gratuito servicio para ' +
    'comparar soluciones de pago en México. ',
  buttonText: 'Compara en solo un minuto',
};

const Hero = () => {
  const language = useLanguageStore((state) => state.language);
  let choosenLanguage: LanguageContent;

  language === 'Español'
    ? (choosenLanguage = content.Español)
    : (choosenLanguage = content.English);

  const isBrowser = typeof window !== 'undefined';
  const [width, setWidth] = useState<number>();

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    if (!isBrowser) {
      return;
    }

    setWidth(window.innerWidth);

    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 798;
  const isTablet = width >= 799 && width <= 1000;
  const isDesktop = width >= 1001;

  return (
    <div className={heroContainer}>
      <Container>
        <div className={miniPad} />
        <Row>
          <Col xl="8" lg="12" md="12">
            <div>
              <h1 className={title}>{choosenLanguage.title}</h1>
              <div className={pad} />
              <h3 className={body}>{choosenLanguage.bodyText}</h3>
              <div className={padBig} />
              <Link to="preferencias">
                <Button className={heroButton} variant="success" size="lg">
                  <div className={buttonTextLightSameLine}>
                    {choosenLanguage.buttonText}
                  </div>
                </Button>
              </Link>
            </div>
          </Col>
          <Col xl="4" lg="12" md="12" className={imageColumn}>
            <Image
              className={cardImage}
              alt="Pagando con tarjeta en terminal punto de venta"
              title="Pagando con tarjeta en TPV"
              src={comprarConTarjeta}
              fluid
            />
          </Col>
        </Row>
        <Row>
          <div className={pad} />
          <PartnerCarousel
            isMobile={isMobile}
            isTablet={isTablet}
            isDesktop={isDesktop}
          />
        </Row>
      </Container>
      <div className={pad} />
      {/* Generated by https://smooth.ie/blogs/news/svg-wavey-transitions-between-sections */}
      <div style="height: 150px; overflow: hidden;">
        <svg
          viewBox="0 0 500 150"
          preserveAspectRatio="none"
          style="height: 100%; width: 100%;"
        >
          <path
            d="M0.00,49.98 C150.00,150.00 349.20,-49.98 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
            style="stroke: none; fill: #ffffff;"
          />
        </svg>
      </div>
    </div>
  );
};

export default Hero;
