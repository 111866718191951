import React from 'react';
import { CarouselProvider, Slider, Slide, Image } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import AztecaLogo from '../../../static/images/BankLogo_Transparent/Bancos1.png';
import BajioLogo from '../../../static/images/BankLogo_Transparent/Bancos2.png';
// import AztecaLogo from '../../../static/images/BankLogo_Transparent/Azteca.png';
// import BajioLogo from '../../../static/images/BankLogo_Transparent/Bajio.png';
// import BanorteLogo from '../../../static/images/BankLogo_Transparent/Banorte.png';
// import BanregioLogo from '../../../static/images/BankLogo_Transparent/Banregio.png';
// import BansiLogo from '../../../static/images/BankLogo_Transparent/Bansi.png';
// import BBVALogo from '../../../static/images/BankLogo_Transparent/BBVA.png';
// import BlumonPayLogo from '../../../static/images/BankLogo_Transparent/BlumonPay.png';
// import CitibanamexLogo from '../../../static/images/BankLogo_Transparent/Citibanamex.png';
// import ClipLogo from '../../../static/images/BankLogo_Transparent/Clip.png';
// import InbursaLogo from '../../../static/images/BankLogo_Transparent/Inbursa.png';
// import MercadoPagoLogo from '../../../static/images/BankLogo_Transparent/MercadoPago.png';
// import MifelLogo from '../../../static/images/BankLogo_Transparent/Mifel.png';
// import MultivaLogo from '../../../static/images/BankLogo_Transparent/Multiva.png';
// import SantanderLogo from '../../../static/images/BankLogo_Transparent/Santander.png';
// import ScotiabankLogo from '../../../static/images/BankLogo_Transparent/Scotiabank.png';
// import SrPagoLogo from '../../../static/images/BankLogo_Transparent/SrPago.png';
// import ZettleLogo from '../../../static/images/BankLogo_Transparent/Zettle.png';
// import BillpocketLogo from '../../../static/images/BankLogo_Transparent/Billpocket.png';
// import GetnetLogo from '../../../static/images/BankLogo_Transparent/Getnet.png';
// import KiwiLogo from '../../../static/images/BankLogo_Transparent/Kiwi.png';
// import NetpayLogo from '../../../static/images/BankLogo_Transparent/Netpay.png';

// THIS FILE USES THE NEW LOGOS FROM OLEKSIYS THEY ARE NOT PERFECT BUT WORKS

const PartnerCarousel: FunctionComponent<{
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}> = (props) => {
  let visibleSlides: number;
  if (props.isMobile) {
    visibleSlides = 3;
  }

  if (props.isTablet) {
    visibleSlides = 6;
  }

  if (props.isDesktop) {
    visibleSlides = 9;
  }

  return (
    <CarouselProvider
      interval={5800}
      isPlaying
      naturalSlideWidth={100}
      naturalSlideHeight={4}
      totalSlides={2}
      visibleSlides={1} // {visibleSlides}
      infinite
      touchEnabled
      dragEnabled
    >
      <Slider>
        <Slide index={0}>
          <Image src={AztecaLogo} hasMasterSpinner />
        </Slide>
        <Slide index={1}>
          <Image src={BajioLogo} hasMasterSpinner />
        </Slide>
        {/* <Slide index={2}>
          <Image src={BanorteLogo} hasMasterSpinner />
        </Slide> */}
        {/* <Slide index={3}>
          <Image src={BanregioLogo} hasMasterSpinner />
        </Slide>
        <Slide index={4}>
          <Image src={BansiLogo} hasMasterSpinner />
        </Slide>
        <Slide index={5}>
          <Image src={BBVALogo} hasMasterSpinner />
        </Slide>
        <Slide index={6}>
          <Image src={BlumonPayLogo} hasMasterSpinner />
        </Slide>
        <Slide index={7}>
          <Image src={CitibanamexLogo} hasMasterSpinner />
        </Slide>
        <Slide index={8}>
          <Image src={ClipLogo} hasMasterSpinner />
        </Slide>
        <Slide index={9}>
          <Image src={InbursaLogo} hasMasterSpinner />
        </Slide>
        <Slide index={10}>
          <Image src={MercadoPagoLogo} hasMasterSpinner />
        </Slide>
        <Slide index={11}>
          <Image src={MifelLogo} hasMasterSpinner />
        </Slide>
        <Slide index={12}>
          <Image src={MultivaLogo} hasMasterSpinner />
        </Slide>
        <Slide index={13}>
          <Image src={SantanderLogo} hasMasterSpinner />
        </Slide>
        <Slide index={14}>
          <Image src={ScotiabankLogo} hasMasterSpinner />
        </Slide>
        <Slide index={15}>
          <Image src={SrPagoLogo} hasMasterSpinner />
        </Slide>
        <Slide index={16}>
          <Image src={ZettleLogo} hasMasterSpinner />
        </Slide>
        <Slide index={17}>
          <Image src={BillpocketLogo} hasMasterSpinner />
        </Slide>
        <Slide index={18}>
          <Image src={GetnetLogo} hasMasterSpinner />
        </Slide>
        <Slide index={19}>
          <Image src={KiwiLogo} hasMasterSpinner />
        </Slide>
        <Slide index={20}>
          <Image src={NetpayLogo} hasMasterSpinner />
        </Slide> */}
      </Slider>
    </CarouselProvider>
  );
};

export default PartnerCarousel;
