// extracted by mini-css-extract-plugin
export var addButton = "Hero-module--addButton--76aad";
export var addButtonSimple = "Hero-module--addButtonSimple--317a1";
export var body = "Hero-module--body--c49ef";
export var buttonText = "Hero-module--buttonText--bfac1";
export var buttonTextLight = "Hero-module--buttonTextLight--f663c";
export var buttonTextLightSameLine = "Hero-module--buttonTextLightSameLine--32f8b";
export var buttonTextSameLine = "Hero-module--buttonTextSameLine--06050";
export var buyButtonCart = "Hero-module--buyButtonCart--88be0";
export var cardImage = "Hero-module--cardImage--4bebb";
export var heroButton = "Hero-module--heroButton--ac955";
export var heroContainer = "Hero-module--heroContainer--8e93a";
export var imageColumn = "Hero-module--imageColumn--a0d65";
export var miniPad = "Hero-module--miniPad--1740b";
export var pad = "Hero-module--pad--20c56";
export var padBig = "Hero-module--padBig--06c4e";
export var subtractButton = "Hero-module--subtractButton--820f0";
export var title = "Hero-module--title--d8008";